import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import moment from "moment"
import { useIntl } from "react-intl"
import facebookLogo from "../images/facebook_gradient_dark.svg"
import ContentPane from "./ContentPane"
import { ALAKERTA_DARK_YELLOW, ALAKERTA_LIGHT_YELLOW, SECONDARY_FONT } from "../util/constants"

const finnishLocaleMessages = {
  months: "tammikuu_helmikuu_maaliskuu_huhtikuu_toukokuu_kesäkuu_heinäkuu_elokuu_syyskuu_lokakuu_marraskuu_joulukuu".split(
    "_"
  ),
  monthsShort: "tammi_helmi_maalis_huhti_touko_kesä_heinä_elo_syys_loka_marras_joulu".split(
    "_"
  ),
  monthsParseExact: true,
  longDateFormat: {
    LT: "HH.mm",
    LTS: "HH.mm.ss",
    L: "DD.MM.YYYY",
    LL: "Do MMMM[ta] YYYY",
    LLL: "Do MMM YYYY, [klo] LT",
    LLLL: "dddd, Do MMMM[ta] YYYY, [klo] LT",
    l: "D.M.YYYY",
    ll: "Do MMM YYYY",
    lll: "Do MMM YYYY, [klo] LT",
    llll: "ddd, Do MMM YYYY, [klo] LT",
  },
}

moment.defineLocale("fi", finnishLocaleMessages)

const eventNameStyle = css`
  font-family: ${SECONDARY_FONT};
  text-transform: uppercase;
`

const facebookLinkStyle = css`
  text-decoration: none;
  color: white;
  margin: 0;
`

const facebookIconStyle = css`
  width: 2.5rem;
  margin-top: 1rem;
`

const FacebookIconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`

const Event = ({ title, date, description, facebookLink }) => {
  const intl = useIntl()
  moment.locale(intl.locale)

  return (
    <ContentPane
      primaryColor={ALAKERTA_LIGHT_YELLOW}
      secondaryColor={ALAKERTA_DARK_YELLOW}
    >
      {date && <p>{moment(new Date(date)).format("LLL")}</p>}
      <h2 css={eventNameStyle}>{title} </h2>
      <span dangerouslySetInnerHTML={{ __html: description }} />
      {facebookLink && facebookLink.toLowerCase().includes('facebook') && (
        <FacebookIconContainer>
          <a
            css={facebookLinkStyle}
            href={facebookLink}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              css={facebookIconStyle}
              src={facebookLogo}
              alt="Event on Facebook"
            />
          </a>
        </FacebookIconContainer>
      )}
    </ContentPane>
  )
}

export default Event
