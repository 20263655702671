import React from "react"
import styled from "@emotion/styled"
import { ALAKERTA_DARK_BACKGROUND, ALAKERTA_GREEN } from "../util/constants"
import Event from "./Event"
import useEventsData from "../hooks/eventHook"
import { useIntl } from "gatsby-plugin-intl"
import DividerIcon from "./DividerIcon"

const PAST_EVENTS_DISPLAY_THRESHOLD = 48 // hours

const EventsStyle = styled.div`
  background: linear-gradient(${ALAKERTA_GREEN}, ${ALAKERTA_DARK_BACKGROUND});
  padding: 1.5rem 0;
  min-height: 80vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation: fade-in 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
`

const Events = () => {
  const intl = useIntl()

  const eventsData = useEventsData()
  const {
    allMarkdownRemark: { edges: events },
  } = eventsData

  const getDateObject = isoDateString => new Date(isoDateString)

  const addHoursToDate = date => date.setHours(date.getHours() + PAST_EVENTS_DISPLAY_THRESHOLD)

  const sortEvents = events =>
    [...events].sort(
      (event1, event2) =>
        getDateObject(event1.node.frontmatter.date) -
        getDateObject(event2.node.frontmatter.date)
    )

  const filterEvents = events =>
    events.filter(event => {
      const lastEventDisplayDate = getDateObject(
        addHoursToDate(getDateObject(event.node.frontmatter.date))
      )

      return lastEventDisplayDate > new Date() ? event : null
    })

  const filteredEvents = filterEvents(events)
  const sortedEvents = sortEvents(filteredEvents)
  return (
    <EventsStyle>
      {sortedEvents && sortedEvents.length !== 0 ? (
        sortedEvents.map(event => {
          const {
            node: {
              frontmatter: { date, title, facebook_link },
              html,
            },
          } = event

          return (
            <>
              <Event
                key={`${title}-${date}`}
                title={title}
                date={date}
                description={html}
                facebookLink={facebook_link}
              />
              <DividerIcon />
            </>
          )
        })
      ) : (
        <>
          <Event
            // In case no events
            title={
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "40vh",
                }}
              >
                {/* todo: review finnish translations */}
                {intl.formatMessage({
                  id: "events.no_events_to_display",
                })}
              </div>
            }
          />
          <DividerIcon />
        </>
      )}
    </EventsStyle>
  )
}

export default Events
