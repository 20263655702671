import { useStaticQuery, graphql } from "gatsby"

const useEventsData = () => {
  const data = useStaticQuery(graphql`
    {
      __typename
      allMarkdownRemark {
        edges {
          node {
            internal {
              content
            }
            html
            frontmatter {
              date
              title
              facebook_link
            }
          }
        }
      }
    }
  `)
  return data
}

export default useEventsData
